import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';

import Layout from '../../components/lms/layout';
import LearningModuleCard from '../../components/lms/learning-module-card';
import Button from '../../atoms/button';

import { wistiaVideoContentEntryParser } from '../../helpers/parser';

const AcademyIndexPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query LearnHomePageQuery {
          contentfulLmsHomePage {
            introCopy {
              introCopy
            }
          }
          contentfulLmsGlobals {
            bookingLink
          }
          allContentfulLmsCourse {
            nodes {
              title
            }
          }
          allContentfulLmsLearningModule(
            sort: { fields: createdAt, order: DESC }
          ) {
            nodes {
              title
              slug
              course {
                title
              }
              image {
                title
                description
                gatsbyImageData(
                  aspectRatio: 1.6
                  placeholder: BLURRED
                  formats: [WEBP, JPG]
                )
              }
              description {
                description
              }
              videoList {
                title
                slug
                description {
                  description
                }
                wistiaVideo {
                  items {
                    hashed_id
                    duration
                    thumbnail {
                      url
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        contentfulLmsHomePage: homePageData,
        contentfulLmsGlobals: globalsData,
        allContentfulLmsCourse: courseData,
        allContentfulLmsLearningModule: learningModulesData,
      }) => {
        const seo = {
          title: 'Revolution Learning Centre',
          description: homePageData.introCopy.introCopy,
        };

        const data = {
          introCopy: homePageData.introCopy.introCopy,
          bookingLink: globalsData.bookingLink,
        };

        const learningModules = learningModulesData.nodes.map((node) => {
          return {
            ...node,
            description: node.description.description,
            videoList: node.videoList.map((videoNode) =>
              wistiaVideoContentEntryParser(videoNode)
            ),
          };
        });

        const courses = courseData.nodes.map((node) => node.title);
        const initialNumber = 10;
        const [activeFilter, setActiveFilter] = useState('All');
        const [list, setList] = useState([
          ...learningModules.slice(0, initialNumber),
        ]);
        const [loadMore, setLoadMore] = useState(false);
        const [hasMore, setHasMore] = useState(
          learningModules.length > initialNumber
        );

        useEffect(() => {
          if (loadMore && hasMore) {
            const currentLength = list.length;
            const nextResults =
              currentLength < learningModules.length
                ? learningModules
                    .filter(
                      (module) =>
                        activeFilter === 'All' ||
                        module.course.title === activeFilter
                    )
                    .slice(currentLength, currentLength + initialNumber)
                : [];
            setList([...list, ...nextResults]);
            setLoadMore(false);
          }
        }, [loadMore, hasMore]);

        useEffect(() => {
          setHasMore(
            list.length <
              learningModules.filter(
                (module) =>
                  activeFilter === 'All' || module.course.title === activeFilter
              ).length
          );
        }, [list]);

        useEffect(() => {
          const newList = [
            ...learningModules
              .filter(
                (module) =>
                  activeFilter === 'All' || module.course.title === activeFilter
              )
              .slice(0, initialNumber),
          ];
          setList(newList);
        }, [activeFilter]);

        return (
          <Layout bookingLink={globalsData.bookingLink} locale="en-AU">
            <Helmet
              htmlAttributes={{
                lang: 'en',
              }}
              title={seo.title}
              link={[
                {
                  rel: 'stylesheet',
                  href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap',
                },
              ]}
              meta={[
                {
                  name: 'description',
                  content: seo.description,
                },
                {
                  name: 'robots',
                  content: 'noindex',
                },
              ]}
            />

            <div className="pt-header-height-mobile lg:pt-header-height-desktop xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-9">
              <p className="text-body-xs text-white">
                <span>Learning Centre</span>
              </p>
            </div>

            <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-16">
              <div className="grid grid-cols-12">
                <div className="col-start-1 col-span-full lg:col-span-6">
                  <h1 className="u-h2 text-white mb-3">Learning centre</h1>
                  <p className="u-body-lg text-white">{data.introCopy}</p>
                </div>
              </div>
            </div>

            <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-16">
              <div className="u-body-sm flex flex-wrap justify-start gap-4 text-white">
                <Button
                  className={
                    activeFilter === 'All' &&
                    'bg-blue-bold !border-blue-bold text-white'
                  }
                  variant="filter"
                  label="All"
                  onClick={() => setActiveFilter('All')}
                />
                {courses
                  .filter(
                    (course) =>
                      learningModules.findIndex(
                        (module) => module.course.title === course
                      ) !== -1
                  )
                  .map((course, index) => (
                    <Button
                      className={
                        activeFilter === course &&
                        'bg-blue-bold !border-blue-bold text-white'
                      }
                      variant="filter"
                      label={`${course} (${
                        learningModules.filter(
                          (module) => module.course.title === course
                        ).length
                      })`}
                      onClick={() => setActiveFilter(course)}
                      key={`course-${index}`}
                    />
                  ))}
              </div>
            </div>

            <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-20 lg:mb-40">
              {list.map((module, index) => {
                return (
                  <div
                    className={`${index !== 0 && 'mt-9'}`}
                    key={`learning-module-${slugify(module.title)}`}
                  >
                    <LearningModuleCard
                      title={module.title}
                      slug={module.slug}
                      image={module.image}
                      courseTitle={module.course.title}
                      description={module.description}
                      videoList={module.videoList}
                      bookingLink={globalsData.bookingLink}
                    />
                  </div>
                );
              })}
              {hasMore && (
                <div className="mt-8 md:mt-16 flex justify-center">
                  <Button
                    className="text-white"
                    variant="quaternary"
                    as="button"
                    onClick={() => setLoadMore(true)}
                    label="Load more"
                  />
                </div>
              )}
            </div>
          </Layout>
        );
      }}
    />
  );
};

export default AcademyIndexPage;
